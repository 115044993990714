/** Use it to check whether the app is running under development mode. **/
export const IS_DEV_ENV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

/** Use it to check whether the app is running in test environment. **/
export const IS_TEST_ENV = process.env.NODE_ENV === 'test'

/** Control the debounce delay value in a central place. **/
export const GENERAL_INPUT_DEBOUNCE_MS = 300

/** Message types supported by the main application message display toast. **/
export const DISPLAYED_MESSAGE_TYPES = {
  INFO: 'info',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning'
}

/** By default, messages are auto-hidden after AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT seconds **/
export const AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT = 5

export const BROWSER_NOTIFICATION_PERMISSION_VALUES = {
  DENIED: 'denied',
  GRANTED: 'granted',
  DEFAULT: 'default'
}

export const HTTP_RESPONSE_CODES = {
  SUCCESS: 200,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  SERVER_ERROR: 500
}

export const LOGO_URL = '/android-chrome-192x192.png'

export const CLICKER_ID_COOKIE_NAME = 'deviceId'

/** Transactional message events supported by the application **/
export const TRANSACTIONAL_MESSAGE_EVENT_ID = {
  SENT: 'SENT',
  DELIVERED: 'DELIVERED',
  FIRST_OPENED: 'FIRST_OPENED',
  UNIQUE_OPEN_BY_A_PROXY: 'UNIQUE_PROXY_OPEN',
  CLICKED: 'CLICKED'
}

/** Value of "Select all values" select option **/
export const ALL_VALUES_SELECT_OPTION_VALUE = ''

export const DEFAULT_ZONE_RADIUS_M = 5000
