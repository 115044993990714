import RESTModelNormalizationSchemas from './models/RESTModelNormalizationSchemas'
import LanguageDataStoreSlice from './slices/data/LanguageDataStoreSlice'
import UserDataStoreSlice from './slices/data/UserDataStoreSlice'
import LinkDataStoreSlice from './slices/data/LinkDataStoreSlice'
import ClickDataStoreSlice from './slices/data/ClickDataStoreSlice'
import ApiKeyDataStoreSlice from './slices/data/ApiKeyDataStoreSlice'
import GloballyExcludedClickerDataStoreSlice from './slices/data/GloballyExcludedClickerDataStoreSlice'
import MessageClientDataStoreSlice from './slices/data/MessageClientDataStoreSlice'
import BrevoTransactionalMessageEventDataStoreSlice from './slices/data/BrevoTransactionalMessageEventDataStoreSlice'
import LinkCategoryDataStoreSlice from './slices/data/LinkCategoryDataStoreSlice'
import SalesAgentDataStoreSlice from './slices/data/SalesAgentDataStoreSlice'
import ClickSyncProcessDataStoreSlice from './slices/data/ClickSyncProcessDataStoreSlice'
import ClickSyncProcessLogDataStoreSlice from './slices/data/ClickSyncProcessLogDataStoreSlice'
import ZoneDataStoreSlice from './slices/data/ZoneDataStoreSlice'
import ZoneClickDataStoreSlice from './slices/data/ZoneClickDataStoreSlice'

/** Data-store slices mapped by their associated store normalization entity name  */
export const NORMALIZATION_ENTITY_NAME_TO_DATA_STORE_SLICE_MAPPING = {
  [RESTModelNormalizationSchemas.language.entityName]: LanguageDataStoreSlice,
  [RESTModelNormalizationSchemas.user.entityName]: UserDataStoreSlice,
  [RESTModelNormalizationSchemas.link.entityName]: LinkDataStoreSlice,
  [RESTModelNormalizationSchemas.click.entityName]: ClickDataStoreSlice,
  [RESTModelNormalizationSchemas.apiKey.entityName]: ApiKeyDataStoreSlice,
  [RESTModelNormalizationSchemas.globallyExcludedClicker.entityName]: GloballyExcludedClickerDataStoreSlice,
  [RESTModelNormalizationSchemas.messageClient.entityName]: MessageClientDataStoreSlice,
  [RESTModelNormalizationSchemas.brevoTransactionalMessageEvent.entityName]: BrevoTransactionalMessageEventDataStoreSlice,
  [RESTModelNormalizationSchemas.linkCategory.entityName]: LinkCategoryDataStoreSlice,
  [RESTModelNormalizationSchemas.salesAgent.entityName]: SalesAgentDataStoreSlice,
  [RESTModelNormalizationSchemas.clickSyncProcess.entityName]: ClickSyncProcessDataStoreSlice,
  [RESTModelNormalizationSchemas.clickSyncProcessLog.entityName]: ClickSyncProcessLogDataStoreSlice,
  [RESTModelNormalizationSchemas.zone.entityName]: ZoneDataStoreSlice,
  [RESTModelNormalizationSchemas.zoneClick.entityName]: ZoneClickDataStoreSlice
}
