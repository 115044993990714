import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import ZoneClickDataStoreSagaHandlers from '../../sagas/data/ZoneClickDataStore'

const sliceName = 'zoneClicksStore'

const ZoneClickDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  ZoneClickDataStoreSagaHandlers
)

export default ZoneClickDataStoreSlice
