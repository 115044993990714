import RESTModelNormalizationSchemas from './models/RESTModelNormalizationSchemas'
import StoreLanguage from './models/Language'
import StoreProfile from './models/Profile'
import StoreUser from './models/User'
import StoreLink from './models/Link'
import StoreClick from './models/Click'
import StoreApiKey from './models/ApiKey'
import StoreGloballyExcludedClicker from './models/GloballyExcludedClicker'
import StoreMessageClient from './models/MessageClient'
import StoreBrevoTransactionalMessageEvent from './models/BrevoTransactionalMessageEvent'
import StoreLinkCategory from './models/LinkCategory'
import StoreSalesAgent from './models/SalesAgent'
import StoreClickSyncProcess from './models/ClickSyncProcess'
import StoreClickSyncProcessLog from './models/ClickSyncProcessLog'
import StoreZone from './models/Zone'
import StoreZoneClick from './models/ZoneClick'

export const NORMALIZATION_ENTITY_NAME_TO_STORE_ENTITY_NAME_MAPPING = {
  [RESTModelNormalizationSchemas.language.entityName]: StoreLanguage,
  [RESTModelNormalizationSchemas.profile.entityName]: StoreProfile,
  [RESTModelNormalizationSchemas.user.entityName]: StoreUser,
  [RESTModelNormalizationSchemas.link.entityName]: StoreLink,
  [RESTModelNormalizationSchemas.click.entityName]: StoreClick,
  [RESTModelNormalizationSchemas.apiKey.entityName]: StoreApiKey,
  [RESTModelNormalizationSchemas.globallyExcludedClicker.entityName]: StoreGloballyExcludedClicker,
  [RESTModelNormalizationSchemas.messageClient.entityName]: StoreMessageClient,
  [RESTModelNormalizationSchemas.brevoTransactionalMessageEvent.entityName]: StoreBrevoTransactionalMessageEvent,
  [RESTModelNormalizationSchemas.linkCategory.entityName]: StoreLinkCategory,
  [RESTModelNormalizationSchemas.salesAgent.entityName]: StoreSalesAgent,
  [RESTModelNormalizationSchemas.clickSyncProcess.entityName]: StoreClickSyncProcess,
  [RESTModelNormalizationSchemas.clickSyncProcessLog.entityName]: StoreClickSyncProcessLog,
  [RESTModelNormalizationSchemas.zone.entityName]: StoreZone,
  [RESTModelNormalizationSchemas.zoneClick.entityName]: StoreZoneClick
}
