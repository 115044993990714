import ZoneClickService from '../../../services/ZoneClick'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const ZoneClickDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'zoneClick',
  ZoneClickService,
  RESTModelNormalizationSchemas.zoneClick.schema,
  [
    RESTModelNormalizationSchemas.zoneClick.entityName,
    RESTModelNormalizationSchemas.zone.entityName,
    RESTModelNormalizationSchemas.click.entityName,
    RESTModelNormalizationSchemas.link.entityName
  ]
)

export default ZoneClickDataStoreSagaHandlers
