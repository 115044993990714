import useAppBarTitle from './useAppBarTitle'
import useAuthState from './useAuthState'
import useAvailableThemeNamesAsSelectOptions from './useAvailableThemeNamesAsSelectOptions'
import useBrevoTransactionalMessageEvents from './useBrevoTransactionalMessageEvents'
import useFramelessModeTurnedOn from './useFramelessModeTurnedOn'
import useLanguagesAsSelectOptions from './useLanguagesAsSelectOptions'
import useLinkCategories from './useLinkCategories'
import useLinkCategoriesAsSelectOptions from './useLinkCategoriesAsSelectOptions'
import useLoading from './useLoading'
import useNormalizedApiKeys from './useNormalizedApiKeys'
import useNormalizedBrevoTransactionalMessageEvents from './useNormalizedBrevoTransactionalMessageEvents'
import useNormalizedClicks from './useNormalizedClicks'
import useNormalizedClickSyncProcesses from './useNormalizedClickSyncProcesses'
import useNormalizedClickSyncProcessLogs from './useNormalizedClickSyncProcessLogs'
import useNormalizedGloballyExcludedClickers from './useNormalizedGloballyExcludedClickers'
import useNormalizedLanguages from './useNormalizedLanguages'
import useNormalizedLinkCategories from './useNormalizedLinkCategories'
import useNormalizedLinks from './useNormalizedLinks'
import useNormalizedLinkClicks from './useNormalizedLinkClicks'
import useNormalizedMessageClients from './useNormalizedMessageClients'
import useNormalizedProfile from './useNormalizedProfile'
import useNormalizedUsers from './useNormalizedUsers'
import useNormalizedZoneClicks from './useNormalizedZoneClicks'
import useNormalizedZones from './useNormalizedZones'
import useApiKeys from './useApiKeys'
import useClicks from './useClicks'
import useClickSyncExecutionStatuses from './useClickSyncExecutionStatuses'
import useClickSyncExecutionStatusesAsSelectOptions from './useClickSyncExecutionStatusesAsSelectOptions'
import useClickSyncProcesses from './useClickSyncProcesses'
import useClickSyncProcessLogs from './useClickSyncProcessLogs'
import useClickSyncSettings from './useClickSyncSettings'
import useGloballyExcludedClickers from './useGloballyExcludedClickers'
import useLinks from './useLinks'
import useMessageClients from './useMessageClients'
import useProfile from './useProfile'
import useNormalizedSalesAgents from './useNormalizedSalesAgents'
import useRoles from './useRoles'
import useRolesAsSelectOptions from './useRolesAsSelectOptions'
import useSalesAgentsAsSelectOptions from './useSalesAgentsAsSelectOptions'
import useTransactionalMessageEvents from './useTransactionalMessageEvents'
import useTransactionalMessageEventsAsSelectOptions from './useTransactionalMessageEventsAsSelectOptions'
import useUsers from './useUsers'
import useZoneClicks from './useZoneClicks'
import useZones from './useZones'

// TODO: refactor non-normalized data hooks to use a deNormalizationConfig parameter(look at how useUsers is implemented)
const storeHooks = {
  useAppBarTitle,
  useAuthState,
  useAvailableThemeNamesAsSelectOptions,
  useBrevoTransactionalMessageEvents,
  useFramelessModeTurnedOn,
  useLanguagesAsSelectOptions,
  useLinkCategories,
  useLinkCategoriesAsSelectOptions,
  useLoading,
  useNormalizedApiKeys,
  useNormalizedBrevoTransactionalMessageEvents,
  useNormalizedClicks,
  useNormalizedClickSyncProcesses,
  useNormalizedClickSyncProcessLogs,
  useNormalizedGloballyExcludedClickers,
  useNormalizedLanguages,
  useNormalizedLinkCategories,
  useNormalizedLinks,
  useNormalizedLinkClicks,
  useNormalizedMessageClients,
  useNormalizedProfile,
  useNormalizedUsers,
  useNormalizedZoneClicks,
  useNormalizedZones,
  useApiKeys,
  useClicks,
  useClickSyncExecutionStatuses,
  useClickSyncExecutionStatusesAsSelectOptions,
  useClickSyncProcesses,
  useClickSyncProcessLogs,
  useClickSyncSettings,
  useGloballyExcludedClickers,
  useLinks,
  useMessageClients,
  useProfile,
  useNormalizedSalesAgents,
  useRoles,
  useRolesAsSelectOptions,
  useSalesAgentsAsSelectOptions,
  useTransactionalMessageEvents,
  useTransactionalMessageEventsAsSelectOptions,
  useUsers,
  useZoneClicks,
  useZones
}

export default storeHooks
