import { useMemo } from 'react'
import useNormalizedLinkCategories from './useNormalizedLinkCategories'
import { useTranslation } from 'react-i18next'

const defaultDeNormalizationConfig = {}

/**
 * Returns requested linkCategory(s) from state.data.linkCategories in a normalized fashion.
 * @param linkCategoryIds {string | string[]}: id of requested linkCategories.
 * @param asMap {boolean}: when multiple linkCategories are requested, return linkCategories as an object mapping linkCategories by their id.
 * @param deNormalizationConfig {{}}:
 * specify which related models are merged into the linkCategory object. Default config returns the same result as
 * useNormalizedLinkCategories.
 * @param fetchAllEnabled {boolean}: enable fetching of all linkCategories, when no linkCategory id is passed.
 * @returns {StoreLinkCategory | StoreLinkCategory[]}
 */
const useLinkCategories = (
  linkCategoryIds = null,
  asMap = false,
  deNormalizationConfig = defaultDeNormalizationConfig,
  fetchAllEnabled = false
) => {
  const normalizedLinkCategories = useNormalizedLinkCategories(linkCategoryIds, asMap, fetchAllEnabled)
  const { t } = useTranslation()

  return useMemo(
    () => {
      const populateLinkCategory = linkCategory => ({
        ...linkCategory,
        name: t(`LinkCategories.${linkCategory.name}`, linkCategory.name)
      })

      return normalizedLinkCategories && (
        typeof linkCategoryIds === 'string' || typeof linkCategoryIds === 'number'
          ? populateLinkCategory(normalizedLinkCategories)
          : asMap
            ? Object.keys(normalizedLinkCategories)
              .reduce((linkCategories, linkCategoryId) => {
                linkCategories[linkCategoryId] = populateLinkCategory(normalizedLinkCategories[linkCategoryId])
                return linkCategories
              }, {})
            : normalizedLinkCategories.map(populateLinkCategory)
      )
    },
    [normalizedLinkCategories, linkCategoryIds, asMap, t]
  )
}

export default useLinkCategories
