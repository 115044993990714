import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 *
 * Search for zone clicks.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = async listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.ZONE_CLICKS,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

const ZoneClickService = {
  FindMany
}

export default ZoneClickService
