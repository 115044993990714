import { markupRoutesWithPathHelperProperties } from './utilities'

/**
 * Define all routes in the ROUTE_DEFINITIONS constant.
 * Each route:
 *  - should have a path, relative to it's parent, defined inside the `_path` property
 *  - can have one or many sub-routes, defined inside an attribute with their own name
 *  Route definition example:
 *  {
 *    _path: 'routepath',
 *    SUB_ROUTE_1: {
 *      _path: 'subRoute1'
 *    },
 *    SUB_ROUTE_2: {
 *      _path: 'subRoute2',
 *      SUB_ROUTE_1: {
 *        _path: 'subRoute1',
 *      }
 *    }
 *  }
 *  !IMPORTANT:
 *  - Each route has the following property names reserved: `_path`, `_parent`, `RELATIVE_PATH`, `ABSOLUTE_PATH`.
 *  All other properties are considered sub-routes.
 */
const ROUTE_DEFINITIONS = {
  _path: '/',
  LOGIN: {
    _path: 'login'
  },
  ADMIN: {
    _path: 'admin',
    DASHBOARD: {
      _path: 'dashboard'
    },
    USERS: {
      _path: 'users',
      CREATE: {
        _path: 'create'
      },
      EDIT: {
        _path: ':userId/edit'
      }
    },
    LINKS: {
      _path: 'links',
      CREATE: {
        _path: 'create'
      },
      EDIT: {
        _path: ':linkId/edit'
      },
      CLICKS: {
        _path: ':linkId/clicks'
      }
    },
    API_KEYS: {
      _path: 'api-keys',
      CREATE: {
        _path: 'create'
      },
      EDIT: {
        _path: ':apiKeyId/edit'
      }
    },
    GLOBALLY_EXCLUDED_CLICKERS: {
      _path: 'globally-excluded-clickers'
    },
    CLICK_SYNC: {
      _path: 'click-sync',
      PROCESSES: {
        _path: 'processes',
        ALL_LOGS: {
          _path: 'logs'
        },
        LOGS: {
          _path: ':clickSyncProcessId/logs'
        }
      },
      SETTINGS: {
        _path: 'settings'
      }
    },
    MESSAGE_CLIENTS: {
      _path: 'message-clients',
      ACTIVITY: {
        _path: 'activity'
      },
      DETAILS: {
        _path: ':messageClientId/details'
      }
    },
    ZONES: {
      _path: 'zones',
      EDIT: {
        _path: ':zoneId/edit'
      },
      CREATE: {
        _path: 'create'
      },
      CLICKS: {
        _path: ':zoneId/clicks'
      }
    },
    PROFILE: {
      _path: 'profile',
      EDIT: {
        _path: 'edit'
      }
    }
  }
}

export const ROUTES = markupRoutesWithPathHelperProperties(ROUTE_DEFINITIONS)
