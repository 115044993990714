import { useMemo } from 'react'
import useNormalizedMessageClients from './useNormalizedMessageClients'

const defaultDeNormalizationConfig = {}

/**
 * Returns requested message client(s) from state.data.messageClients in a normalized fashion.
 * @param messageClientIds {string | string[]}: id of requested message clients.
 * @param asMap {boolean}: when multiple message clients are requested, return message clients as an object mapping message clients by their id.
 * @param deNormalizationConfig {{}}:
 * specify which related models are merged into the message client object. Default config returns the same result as
 * useNormalizedMessageClients.
 * @param fetchAllEnabled {boolean}: enable fetching of all message clients, when no message client id is passed.
 * @returns {StoreMessageClient | StoreMessageClient[]}
 */
const useMessageClients = (
  messageClientIds = null,
  asMap = false,
  deNormalizationConfig = defaultDeNormalizationConfig,
  fetchAllEnabled = false
) => {
  const normalizedMessageClients = useNormalizedMessageClients(messageClientIds, asMap, fetchAllEnabled)

  return useMemo(
    () => {
      const populateMessageClient = messageClient => messageClient

      return normalizedMessageClients && (
        typeof messageClientIds === 'string' || typeof messageClientIds === 'number'
          ? populateMessageClient(normalizedMessageClients)
          : asMap
            ? Object.keys(normalizedMessageClients)
              .reduce((messageClients, messageClientId) => {
                messageClients[messageClientId] = populateMessageClient(normalizedMessageClients[messageClientId])
                return messageClients
              }, {})
            : normalizedMessageClients.map(populateMessageClient)
      )
    },
    [normalizedMessageClients, messageClientIds, asMap]
  )
}

export default useMessageClients
