import ZoneClickDataStoreSelectors from '../selectors/data/ZoneClicks'
import ZoneClickDataStoreSlice from '../slices/data/ZoneClickDataStoreSlice'
import StoreZoneClick from '../models/ZoneClick'
import useDataStoreItems from './helpers/useDataStoreItems'

const JSONStoreZoneClickToStoreZoneClick = JSONStoreZoneClick => StoreZoneClick.FromJSON(JSONStoreZoneClick)

/**
 * Returns requested-/all zone click(s) from state.data.zoneClicks.
 * @param zoneClickIds {string | string[]}: id of requested zone clicks.
 * @param asMap {boolean}: when multiple zone clicks are requested, return zone clicks as an object mapping zone clicks by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all zone clicks, when no zone click id is passed.
 * @returns {StoreZoneClick | StoreZoneClick[]}
 */
const useNormalizedZoneClicks = (
  zoneClickIds = null,
  asMap = false,
  fetchAllEnabled = false
) => useDataStoreItems(
  zoneClickIds,
  ZoneClickDataStoreSelectors.zoneClicks,
  ZoneClickDataStoreSlice.actions.fetch,
  JSONStoreZoneClickToStoreZoneClick,
  asMap,
  fetchAllEnabled
)

export default useNormalizedZoneClicks
