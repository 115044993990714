class StoreZoneClick {
  constructor (
    id = undefined,
    zoneId,
    clickId,
    linkId
  ) {
    this.id = id
    this.zoneId = zoneId
    this.zone = null
    this.clickId = clickId
    this.click = null
    this.linkId = linkId
    this.link = null
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      // zoneId is read-only
      // clickId is read-only
      // linkId is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    zoneId,
    clickId,
    linkId
  }) {
    return new StoreZoneClick(
      id,
      zoneId,
      clickId,
      linkId
    )
  }

  toJSON () {
    return {
      id: this.id,
      zoneId: this.zoneId,
      clickId: this.clickId,
      linkId: this.linkId
    }
  }

  static FromJSON ({
    id,
    zoneId,
    clickId,
    linkId
  }) {
    return new StoreZoneClick(
      id,
      zoneId,
      clickId,
      linkId
    )
  }
}

export default StoreZoneClick
