import { useMemo } from 'react'
import useNormalizedZones from './useNormalizedZones'
import useZoneClicks from './useZoneClicks'

const defaultDeNormalizationConfig = {
  clicks: false
}

const zoneClickDeNormalizationConfig = {
  click: true,
  link: true
}

/**
 * Returns requested-/all zone(s) from state.data.zones in a non-normalized fashion.
 * @param zoneIds {string | string[]}: id of requested zones.
 * @param asMap {boolean}: when multiple zones are requested, return zones as an object mapping zones by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all items, when no itemId is passed
 * @param deNormalizationConfig {{clicks: boolean}}: specify which related models are merged into the zone object.
 * Default config returns the same result as useNormalizedZones.
 * @returns {StoreZone | StoreZone[]}
 */
const useZones = (
  zoneIds = null,
  asMap = false,
  fetchAllEnabled = false,
  deNormalizationConfig = defaultDeNormalizationConfig
) => {
  const normalizedZones = useNormalizedZones(zoneIds, asMap, fetchAllEnabled)
  const zoneClicks = useZoneClicks(
    null,
    false,
    zoneClickDeNormalizationConfig,
    deNormalizationConfig.clicks
  )

  return useMemo(
    () => {
      const populateZone = zone => {
        const populatedZone = { ...zone }
        if (deNormalizationConfig.clicks) {
          populatedZone.clicks = zoneClicks?.filter(zoneClick => zoneClick.zoneId === zone.id) || []
        }
        return populatedZone
      }

      return normalizedZones && (
        typeof zoneIds === 'string' || typeof zoneIds === 'number'
          ? populateZone(normalizedZones)
          : asMap
            ? Object.keys(normalizedZones)
              .reduce((zones, zoneId) => {
                zones[zoneId] = populateZone(normalizedZones[zoneId])
                return zones
              }, {})
            : normalizedZones.map(populateZone)
      )
    },
    [zoneIds, normalizedZones, zoneClicks, deNormalizationConfig, asMap]
  )
}

export default useZones
