import { useMemo } from 'react'
import useNormalizedZoneClicks from './useNormalizedZoneClicks'
import uniq from 'lodash/uniq'
import useNormalizedClicks from './useNormalizedClicks'
import useNormalizedLinks from './useNormalizedLinks'

const defaultDeNormalizationConfig = {
  click: false,
  link: false
}

/**
 * Returns requested zone click(s) from state.data.zoneClicks in a normalized fashion.
 * @param zoneClickIds {string | string[]}: id of requested zone clicks.
 * @param asMap {boolean}: when multiple zone clicks are requested, return zone clicks as an object mapping zone clicks by their id.
 * @param deNormalizationConfig {{click: boolean, link: boolean}}:
 * specify which related models are merged into the zone click object. Default config returns the same result as
 * useNormalizedZoneClicks.
 * @param fetchAllEnabled {boolean}: enable fetching of all zone clicks, when no zone click id is passed.
 * @returns {StoreZoneClick | StoreZoneClick[]}
 */
const useZoneClicks = (
  zoneClickIds = null,
  asMap = false,
  deNormalizationConfig = defaultDeNormalizationConfig,
  fetchAllEnabled = false
) => {
  const normalizedZoneClicks = useNormalizedZoneClicks(zoneClickIds, asMap, fetchAllEnabled)
  const relatedIds = useMemo(
    () => {
      const reducedZoneClicks = typeof zoneClickIds === 'string'
        ? asMap
          ? normalizedZoneClicks && { [normalizedZoneClicks.id]: normalizedZoneClicks }
          : normalizedZoneClicks && [normalizedZoneClicks]
        : normalizedZoneClicks
      const relatedIds = (
        asMap
          ? Object.values(reducedZoneClicks || {})
          : reducedZoneClicks || []
      ).reduce(
        (relatedIds, zoneClick) => {
          zoneClick.clickId && relatedIds.clickIds.push(zoneClick.clickId)
          zoneClick.linkId && relatedIds.linkIds.push(zoneClick.linkId)

          return relatedIds
        },
        { clickIds: [], linkIds: [] }
      )

      relatedIds.clickIds = uniq(relatedIds.clickIds)
      relatedIds.linkIds = uniq(relatedIds.linkIds)

      return relatedIds
    },
    [normalizedZoneClicks, asMap, zoneClickIds]
  )

  const normalizedClicks = useNormalizedClicks(
    deNormalizationConfig.click
      ? relatedIds?.clickIds
      : null,
    true,
    false)

  const normalizedLinks = useNormalizedLinks(
    deNormalizationConfig.link
      ? relatedIds?.linkIds
      : null,
    true,
    false
  )

  return useMemo(
    () => {
      const populateZoneClick = zoneClick => {
        if (deNormalizationConfig.click) {
          zoneClick.click = normalizedClicks[zoneClick.clickId]
        }
        if (deNormalizationConfig.link) {
          zoneClick.link = normalizedLinks[zoneClick.linkId]
        }

        return zoneClick
      }

      return normalizedZoneClicks && (
        typeof zoneClickIds === 'string' || typeof zoneClickIds === 'number'
          ? populateZoneClick(normalizedZoneClicks)
          : asMap
            ? Object.keys(normalizedZoneClicks)
              .reduce((zoneClicks, zoneClickId) => {
                zoneClicks[zoneClickId] = populateZoneClick(normalizedZoneClicks[zoneClickId])
                return zoneClicks
              }, {})
            : normalizedZoneClicks.map(populateZoneClick)
      )
    },
    [normalizedZoneClicks, zoneClickIds, normalizedClicks, normalizedLinks, asMap, deNormalizationConfig]
  )
}

export default useZoneClicks
