import { useMemo } from 'react'
import useNormalizedClickSyncProcesses from './useNormalizedClickSyncProcesses'

const defaultDeNormalizationConfig = {}

/**
 * Returns requested clickSyncProcess(s) from state.data.clickSyncProcesses in a normalized fashion.
 * @param clickSyncProcessIds {string | string[]}: id of requested clickSyncProcesses.
 * @param asMap {boolean}: when multiple clickSyncProcesses are requested, return clickSyncProcesses as an object mapping clickSyncProcesses by their id.
 * @param deNormalizationConfig {{family: boolean, language: boolean, role: boolean, photo: boolean, devices: boolean}}:
 * specify which related models are merged into the clickSyncProcess object. Default config returns the same result as
 * useNormalizedClickSyncProcesses.
 * @param fetchAllEnabled {boolean}: enable fetching of all clickSyncProcesses, when no clickSyncProcess id is passed.
 * @returns {StoreClickSyncProcess | StoreClickSyncProcess[]}
 */
const useClickSyncProcesses = (
  clickSyncProcessIds = null,
  asMap = false,
  deNormalizationConfig = defaultDeNormalizationConfig,
  fetchAllEnabled = false
) => {
  const normalizedClickSyncProcesses = useNormalizedClickSyncProcesses(clickSyncProcessIds, asMap, fetchAllEnabled)

  return useMemo(
    () => {
      const populateClickSyncProcess = clickSyncProcess => clickSyncProcess

      return normalizedClickSyncProcesses && (
        typeof clickSyncProcessIds === 'string' || typeof clickSyncProcessIds === 'number'
          ? populateClickSyncProcess(normalizedClickSyncProcesses)
          : asMap
            ? Object.keys(normalizedClickSyncProcesses)
              .reduce((clickSyncProcesses, clickSyncProcessId) => {
                clickSyncProcesses[clickSyncProcessId] = populateClickSyncProcess(normalizedClickSyncProcesses[clickSyncProcessId])
                return clickSyncProcesses
              }, {})
            : normalizedClickSyncProcesses.map(populateClickSyncProcess)
      )
    },
    [normalizedClickSyncProcesses, clickSyncProcessIds, asMap/*, deNormalizationConfig */]
  )
}

export default useClickSyncProcesses
