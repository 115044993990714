import { useMemo } from 'react'
import useNormalizedGloballyExcludedClickers from './useNormalizedGloballyExcludedClickers'

const defaultDeNormalizationConfig = {}

/**
 * Returns requested globallyExcludedClicker(s) from state.data.globallyExcludedClickers in a normalized fashion.
 * @param globallyExcludedClickerIds {string | string[]}: id of requested globallyExcludedClickers.
 * @param asMap {boolean}: when multiple globallyExcludedClickers are requested, return globallyExcludedClickers as an object mapping globallyExcludedClickers by their id.
 * @param deNormalizationConfig {{}}:
 * specify which related models are merged into the globallyExcludedClicker object. Default config returns the same result as
 * useNormalizedGloballyExcludedClickers.
 * @param fetchAllEnabled {boolean}: enable fetching of all globallyExcludedClickers, when no globallyExcludedClicker id is passed.
 * @returns {StoreGloballyExcludedClicker | StoreGloballyExcludedClicker[]}
 */
const useGloballyExcludedClickers = (
  globallyExcludedClickerIds = null,
  asMap = false,
  deNormalizationConfig = defaultDeNormalizationConfig,
  fetchAllEnabled = false
) => {
  const normalizedGloballyExcludedClickers = useNormalizedGloballyExcludedClickers(globallyExcludedClickerIds, asMap, fetchAllEnabled)

  return useMemo(
    () => {
      const populateGloballyExcludedClicker = globallyExcludedClicker => globallyExcludedClicker

      return normalizedGloballyExcludedClickers && (
        typeof globallyExcludedClickerIds === 'string' || typeof globallyExcludedClickerIds === 'number'
          ? populateGloballyExcludedClicker(normalizedGloballyExcludedClickers)
          : asMap
            ? Object.keys(normalizedGloballyExcludedClickers)
              .reduce((globallyExcludedClickers, globallyExcludedClickerId) => {
                globallyExcludedClickers[globallyExcludedClickerId] = populateGloballyExcludedClicker(normalizedGloballyExcludedClickers[globallyExcludedClickerId])
                return globallyExcludedClickers
              }, {})
            : normalizedGloballyExcludedClickers.map(populateGloballyExcludedClicker)
      )
    },
    [normalizedGloballyExcludedClickers, globallyExcludedClickerIds, asMap]
  )
}

export default useGloballyExcludedClickers
