import { useMemo } from 'react'
import useNormalizedLinks from './useNormalizedLinks'
import useNormalizedUsers from './useNormalizedUsers'
import uniq from 'lodash/uniq'
import useNormalizedLinkCategories from './useNormalizedLinkCategories'

const defaultDeNormalizationConfig = { generatedByUser: false, category: false }

/**
 * Returns requested link(s) from state.data.links in a normalized fashion.
 * @param linkIds {string | string[]}: id of requested links.
 * @param asMap {boolean}: when multiple links are requested, return links as an object mapping links by their id.
 * @param deNormalizationConfig {{ generatedByUser: boolean, category: boolean }}:
 * specify which related models are merged into the link object. Default config returns the same result as
 * useNormalizedLinks.
 * @param fetchAllEnabled {boolean}: enable fetching of all links, when no link id is passed.
 * @returns {StoreLink | StoreLink[]}
 */
const useLinks = (
  linkIds = null,
  asMap = false,
  deNormalizationConfig = defaultDeNormalizationConfig,
  fetchAllEnabled = false
) => {
  const normalizedLinks = useNormalizedLinks(linkIds, asMap, fetchAllEnabled)
  const relatedIds = useMemo(
    () => {
      const reducedLinks = typeof linkIds === 'string'
        ? asMap
          ? normalizedLinks && { [normalizedLinks.id]: normalizedLinks }
          : normalizedLinks && [normalizedLinks]
        : normalizedLinks

      const relatedIds = (
        asMap
          ? Object.values(reducedLinks || {})
          : reducedLinks || []
      ).reduce(
        (relatedIds, link) => {
          link.generatedBy && relatedIds.userIds.push(link.generatedBy)
          link.categoryId && relatedIds.categoryIds.push(link.categoryId)

          return relatedIds
        },
        { userIds: [], categoryIds: [] }
      )
      relatedIds.userIds = uniq(relatedIds.userIds)
      relatedIds.categoryIds = uniq(relatedIds.categoryIds)

      return relatedIds
    },
    [normalizedLinks, asMap, linkIds]
  )

  const users = useNormalizedUsers(
    deNormalizationConfig.generatedByUser
      ? relatedIds?.userIds
      : null,
    true,
    false
  )

  const linkCategories = useNormalizedLinkCategories(
    deNormalizationConfig.category
      ? relatedIds?.categoryIds
      : null,
    true
  )

  return useMemo(
    () => {
      const populateLink = link => {
        if (deNormalizationConfig.generatedByUser) {
          link.generatedByUser = users[link.generatedBy]
        }
        if (deNormalizationConfig.category) {
          link.category = linkCategories[link.categoryId]
        }
        return link
      }

      return normalizedLinks && (
        typeof linkIds === 'string' || typeof linkIds === 'number'
          ? populateLink(normalizedLinks)
          : asMap
            ? Object.keys(normalizedLinks)
              .reduce((links, linkId) => {
                links[linkId] = populateLink(normalizedLinks[linkId])
                return links
              }, {})
            : normalizedLinks.map(populateLink)
      )
    },
    [normalizedLinks, linkIds, asMap, deNormalizationConfig, users, linkCategories]
  )
}

export default useLinks
