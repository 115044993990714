const selectZoneClicks = state => state.data.zoneClicks

const selectZoneClickById = zoneClickId => state => state.data.zoneClicks[zoneClickId]

const ZoneClickDataStoreSelectors = {
  zoneClicks: selectZoneClicks,
  zoneClickById: selectZoneClickById
}

export default ZoneClickDataStoreSelectors
